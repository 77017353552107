<script setup>
import { onMounted, onUnmounted } from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
let map = null;
const route = useRoute();
let query = decode(route.query?.t).split(",");
let lng = query[0];
let lat = query[1];
let center = [];
center[0] = lng;
center[1] = lat;
onMounted(() => {
  AMapLoader.load({
    key: "506613588ca6ca2fba4bb4bf1dd7a676", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  })
    .then(AMap => {
      map = new AMap.Map("container", {
        // 设置地图容器id
        viewMode: "2D", // 是否为3D地图模式
        zoom: 18, // 初始化地图级别
        center: center, // 初始化地图中心点位置
      });
      // 创建 AMap.Icon 实例：
      const icon = new AMap.Icon({
        size: new AMap.Size(269, 80), // 图标尺寸
        image: "http://a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png", // Icon的图像
        imageOffset: new AMap.Pixel(-170, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(269, 80) // 根据所设置的大小拉伸或压缩图片
      });

      // 将 Icon 实例添加到 marker 上:
      const marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-10, -10),
        icon: icon, // 添加 Icon 实例
        title: "",
        zoom: 18
      });

      map.add(marker);
    })
    .catch(e => {
      console.log(e);
    });
});

onUnmounted(() => {
  map?.destroy();
});
</script>

<template>
  <div id="container"></div>
</template>

<style scoped>
#container {
  width: 100%;
  height: 800px;
}
</style>
